import { render, staticRenderFns } from "./Static.vue?vue&type=template&id=a3c03b86&"
import script from "./Static.vue?vue&type=script&lang=js&"
export * from "./Static.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Static.vue"
export default component.exports